import { Icon, Image, Grid } from "semantic-ui-react";
import { useAuth } from "../../hooks";
import { Link, useNavigate } from 'react-router-dom';

import styles from "./JoinLayout.module.scss";

export function JoinLayout(props) {
  const { children } = props;
  const { user } = useAuth();
  const navigate = useNavigate();


  if (user) {
    navigate("/");
    return null;
  }

  return (
    <Grid>
      <Grid.Column only="computer" computer={16}>
        <div className={styles.container}>
          <div className={styles.topBar}>
            <Link to="/">
            </Link>

            <Link to="/">
              <Icon name="close" />
            </Link>
          </div>
            <div className={styles.blockLeft}>{children}</div>            
            <div className={styles.blockRight} />
        </div>
        </Grid.Column>
        <Grid.Column only="mobile" mobile={16}>
        <div className={styles.container}>
          <div className={styles.topBarM}>
            <Link to="/">
              <Image src="/images/logo.png" alt="Gaming" />
            </Link>

            <Link to="/">
              <Icon name="close" />
            </Link>
          </div>
            <div className={styles.blockLeft2}>{children}</div>            
        </div>
        </Grid.Column>
        <Grid.Column only="tablet" tablet={16}>
        <div className={styles.container}>
          <div className={styles.topBarT}>
            <Link to="/">
              <Image src="/images/logo.png" alt="Gaming" />
            </Link>

            <Link to="/">
              <Icon name="close" />
            </Link>
          </div>
            <div className={styles.blockLeft2}>{children}</div>            
        </div>
        </Grid.Column>
      </Grid>
      
  );
}
