import { Container, Icon, Grid } from "semantic-ui-react";
import { map } from "lodash";
import { data } from "./BarTrust.data";

import styles from "./BarTrust.module.scss";

export function BarTrust() {
  return (
    <div className={styles.barTrust}>
      <Container className={styles.content}>
      <Grid columns={3} divided relaxed stackable>
        {
          map(data, (item, i) => (
            <Grid.Column key={i}>
            <div className={styles.block}>
              <Icon key={i} name={item.icon}></Icon>
              <div>
                <h5>{item.title}</h5>
                <span>{item.description}</span>
              </div>
            </div>
            </Grid.Column>
          ))
        }
      </Grid>

      </Container>
    </div>
  )
}