import { Link } from "react-router-dom";
import { Container, Image, Button } from "semantic-ui-react";

import styles from "./Footer.module.scss";

export function Footer() {
  return (
    <div className={styles.footer}>
      <Container>
        <div className={styles.columns}>
          <div>
            <Link to="/">
              <Image src="/images/logo.png" alt="Gaming" />
            </Link>
          </div>

          <div className={styles.social}>
            <Button as="a" href="https://api.whatsapp.com/send?phone=50255177725" circular color="green" icon="whatsapp" />
            <Button as="a" href="http://www.instagram.com/luxnglambagclubgt" circular color="instagram" icon="instagram" />            
            <Button as="a" href="https://www.youtube.com" circular color="youtube" icon="youtube" />
          </div>
        </div>

        <div className={styles.copyright}>
          <span>Copyright © 2024 LUX & GLAM - All rights reserved</span>
        </div>
      </Container>
    </div>
  );
}