import React, { useState } from 'react'
import { useAuth } from "../../../../hooks";
import { Form, Divider, Segment } from 'semantic-ui-react';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from "./ClassificationForm.form";
import { ClassificationFormList } from "../ClassificationFormList";
import {  Classification } from "../../../../api/classification";
import { map } from 'lodash';
import { Separator } from '../../../Shared';

import styles from "./ClassificationForm.module.scss";

const ClassificationController = new Classification();

export function ClassificationForm(props) {
    const { schema, onClose, onReload } = props;
    const [ dataClassif, setDataClassif ] = useState({ id: '', value: '' });
    const [ arrayClassif, setArrayClassif ] = useState(schema.classifications);
    const [ flgMod, setFlgMod ] = useState(false);
    const { user } = useAuth();
    
    const formik = useFormik({
        initialValues       :   initialValues(schema),
        validationSchema    :   validationSchema(),
        validateOnChange    :   false,
        onSubmit : async () =>{
            await ClassificationController.addClassification(arrayClassif);
            onReload();
            onClose();
        }
    })

    const fnAddClassification = async () => {        
        dataClassif.idSchema = schema._id;
        dataClassif.createuser = user.response._id;
    
        if (dataClassif.id && dataClassif.value && dataClassif.order) {
            if (flgMod) {
                // Si flgMod es verdadero, estamos en modo de modificación
                const updatedArrayClassif = arrayClassif.map(classif => 
                    classif._id === dataClassif._id ? dataClassif : classif
                );
                setArrayClassif(updatedArrayClassif);
            } else {
                // Si flgMod es falso, estamos en modo de creación
                setArrayClassif([...arrayClassif, dataClassif]);
            }
            // Limpiar el formulario
            setDataClassif({ id: '', value: '', order: '' });
        } else {
            console.error('Id, valor y orden son requeridos');
        }
    }

    const fnSetValues = (data) =>{
        setFlgMod(true);
        setDataClassif(data);
    }

  return (
    <Form onSubmit={formik.handleSubmit}>
        <Form.Group>
            <Form.Field width={3} className={styles.FormLeft}>
                <label > Esquema </label>
            </Form.Field>
            <Form.Field width={13} className={styles.FormRight}>
                <Form.Input disabled name="name" placeholder="" onChange={formik.handleChange} value={formik.values.name} error={formik.errors.name}/>
            </Form.Field>
        </Form.Group>
        <Divider horizontal>Agregar Clasificación</Divider>
        <Form.Group>
            <Form.Field width={3} className={styles.FormLeft}>
                <label > Id </label>
            </Form.Field>
            <Form.Field width={13} className={styles.FormRight}>
                <Form.Input name="id" placeholder="" onChange= {e => setDataClassif({ ...dataClassif, id: e.target.value})} value={dataClassif.id}/>
            </Form.Field>
        </Form.Group>
        <Form.Group>
            <Form.Field width={3} className={styles.FormLeft}>
                <label > Valor </label>
            </Form.Field>
            <Form.Field width={13} className={styles.FormRight}>
                <Form.Input name="value" placeholder="" onChange= {e => setDataClassif({ ...dataClassif, value: e.target.value})} value={dataClassif.value}/>
            </Form.Field>
        </Form.Group>
        <Form.Group>
            <Form.Field width={3} className={styles.FormLeft}>
                <label > Orden </label>
            </Form.Field>
            <Form.Field width={13} className={styles.FormRight}>
                <Form.Input type='number' name="order" placeholder="" onChange= {e => setDataClassif({ ...dataClassif, order: e.target.value})} value={dataClassif.order}/>
            </Form.Field>
        </Form.Group>
        <Separator height={20}/>
        <Form.Button type="button" primary fluid onClick={fnAddClassification}>{flgMod ? "Actualizar" : "Agregar"}</Form.Button>
        <Divider horizontal>Clasificaciones Actuales</Divider>
        <div className={styles.ListSchema}>
            <Segment style={{overflow: 'auto', maxHeight: 300 }}>
                {
                    map(arrayClassif, (classification, i) => (
                        <Form.Field width={16}>
                            <ClassificationFormList fnSetValues={fnSetValues} classification={classification}/>
                        </Form.Field>
                    ))
                }
            </Segment>
        </div>
        <Form.Button type='submit' primary fluid loading={formik.isSubmitting}> Actualizar </Form.Button>
    </Form>      
  )
}
