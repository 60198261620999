import { ENV, authFetch } from "../utils";

export class Wishlist {
  async check(userId, bagId) {
    try {      
      const url = `${ENV.API_URL}/${ENV.ENDPOINTS.WISHLISTITEM}/${userId}/${bagId}`;

      if(!userId){
        return false;
      } 

      const response = await authFetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;

      if(!result.item){
        return false;
      }      

      return result.item;
    } catch (error) {
      throw error;
    }
  }

  async add(userId, bagId) {
    try {
      const url = `${ENV.API_URL}/${ENV.ENDPOINTS.WISHLIST}`;
      const params = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: {
            user: userId,
            bag: bagId,
          },
        }),
      };

      const response = await authFetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result.wishlistItemStored;
    } catch (error) {
      throw error;
    }
  }

  async delete(id) {
    try {
      const url = `${ENV.API_URL}/${ENV.ENDPOINTS.WISHLIST}/${id}`;
      const params = {
        method: "DELETE",
      };

      const response = await authFetch(url, params);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result;
    } catch (error) {
      throw error;
    }
  }

  async getAll(userId) {
    try {
      
      const url = `${ENV.API_URL}/${ENV.ENDPOINTS.WISHLIST}/${userId}`;
      const response = await authFetch(url);
      const result = await response.json();

      if (response.status !== 200) throw result;

      return result.wishlist;
    } catch (error) {
      throw error;
    }
  }
}