//const SERVER_IP = "localhost:3977";
const SERVER_IP = "luxnglam.com.gt:443"

export const ENV = {
    //SERVER_HOST     :   `http://${SERVER_IP}`,
    //API_URL         :   `http://${SERVER_IP}/api/v1`,
    SERVER_HOST     :   `https://${SERVER_IP}`,
    API_URL         :   `https://${SERVER_IP}/api/v1`,
    ENDPOINTS       :   {
        AUTH            :   {
            REGISTER                :   "auth/register",
            LOGIN                   :   "auth/login",
    
                            },
        USER_ME                 :   "user/me",
        USER                    :   "user",
        USERS                   :   "users",
        ADDRESS                 :   "addresses",
        BAG                     :   "bag",
        BAGBYSTOCK              :   "bagbystock",
        BAGBYID                 :   "bagbyid",
        BAGLAST                 :   "baglast",
        BAGSLAST                :   "bagslast",
        BAGUPDATESTOCK          :   "bagupdatestockbyid",   
        WISHLIST                :   "wishlist",
        WISHLISTITEM            :   "wishlistitem",
        TRADEMARK               :   "trademark",
        SCHEMA                  :   "schema",
        CATALOG                 :   "catalog",
        CLASSIFICATION          :   "classification",
        COMMONSCHEMA            :   "commonschema"
    },
    TOKEN           :   "token",

};