export const data = [
  {      
    title :   "Disponibles",
    url   :   "/category/stock",
    icon  :   "shopping cart"
  },
  {      
    title :   "Vendido",
    url   :   "/category/onRequest",
    icon  :   "calendar alternate outline"
  },
];