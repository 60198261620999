import React from 'react';
import { Seo } from '../../../components/Shared';
import  { Home as HomeAdmnin } from '../../../components/Admin/Home';

export function Home() {

    return (
        <>
            <Seo title="Admin LUX & GLAM"/>
            <HomeAdmnin/>
        </>
    )
}