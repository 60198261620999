import React from 'react';
import { Seo } from '../../../components/Shared';
import  { Classification } from '../../../components/Admin/Classification';

export function ClassificationPage() {
    return (
        <>
            <Seo title="Admin Clasficaciones LUX & GLAM"/>
            <Classification/>
        </>
  )
}
