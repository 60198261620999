import { Container, Icon, Image, Grid } from "semantic-ui-react";
import { WishlistIcon, Separator } from "../../../components/Shared";
import { map } from "lodash";
import { ENV } from "../../../utils/constatants";

import styles from "./Panel.module.scss";

export function Panel(props) {
  const { bagId, bag } = props;
  
  return (
    <>
      <Grid>
        <Grid.Column only='computer' computer={16}>
          <Container className={styles.panel}>
            <div className={styles.imgContiner}>
              <Image src={`${ENV.SERVER_HOST}/${bag.wallpaper}`}/>
            </div>      
            <div className={styles.actionsContainer}>        
                <div>
                  <h2>{bag.trademark.value} - {bag.line} </h2>
                  <div className={styles.moreInfo}>
                      <span>
                        {
                          bag.dimentions
                        }
                      </span>
                      {
                        bag.flgstock ? 
                          <span>
                            <Icon name="check" /> Disponible
                          </span>
                        :
                          <span>
                            <Icon style={{color: "red"}}  name="close icon" /> No Disponible
                          </span>
                      }
                  </div>
                  <div>
                    <h3>Caracteristicas</h3>
                    <span>
                      {
                        map(bag.features, (feature, i) =>(
                          <li key={i} >{`${feature}`}</li>
                        ))
                      }              
                    </span>
                  <br/>
                    <h3>Incluye</h3>
                    <span>
                      {
                        map(bag.items, (item, i) =>(
                          <li key={i} >
                            {`${item}`}
                          </li>
                        ))
                      }
                    </span>  
                  </div>           
                </div>
                <WishlistIcon bagId={bagId} className={styles.heart} />
            </div>      
          </Container>
        </Grid.Column>
      </Grid>

    <Separator height={20} />
    
      <Grid>
        <Grid.Column only='mobile tablet' mobile={16} tablet={16}>
          <Container className={styles.panelMT}>
            <div className={styles.imgContinerMT}>
                <Image src={`${ENV.SERVER_HOST}/${bag.wallpaper}`}/>
            </div>
            <div className={styles.actionsContainerMT}>
            <div>
              <h2>{bag.trademark.value} - {bag.line} </h2>
              <div className={styles.moreInfoMT}>
                <span>
                  {
                    bag.dimentions
                  }
                  </span>
                  {
                    bag.flgstock ? 
                      <span>
                        <Icon name="check" /> Disponible
                      </span>
                    :
                      <span>
                        <Icon style={{color: "red"}}  name="close icon" /> No Disponible
                      </span>
                  }
              </div>            
          <h3>Caracteristicas</h3>
          <span>
            {
              map(bag.features, (feature, i) =>(
                <li key={i} >{`${feature}`}</li>
              ))
            }              
          </span>
        <br/>
          <h3>Incluye </h3>
          <span>
            {
              map(bag.items, (item , i) =>(
                <li key={i} >
                  {`${item}`}
                </li>
              ))
            }
          </span>
          </div>  
        </div>
        </Container>
      </Grid.Column>
    </Grid>
  </>
  );
}