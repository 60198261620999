import { Icon, Button } from "semantic-ui-react";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../../hooks";
import classNames from "classnames";

import styles from "./Account.module.scss";

export function Account() {
    const { user } = useAuth();
    const navigate = useNavigate();

    const goToLogin = () => navigate("/join/sign-in");
    const goToAccount = () => navigate("/account");

    return (
        <div className={styles.account}>    
          <Button icon className={classNames({ [styles.user]: user })}>
            <Icon name="user outline" onClick={user ? goToAccount : goToLogin} />
          </Button>
        </div>
      );
}
