import { Container } from "semantic-ui-react";
import { Video } from "./Video";
import { Gallery } from "./Gallery";
import { Separator } from "../../../components/Shared";

export function Media(props) {
  const { folder, video = null, screenshots } = props;

  return (
    <Container>
      <h2>Visuales</h2>      
      {
        
        video && <> <Separator height={30} /> <Video video={video} /></>

      }
      <Separator height={20} />
      <Gallery folder={folder} screenshots={screenshots} />
    </Container>
  );
}