
import styles from "./UserItem.module.scss"; 

export function UserItem(props) {
  const { user } = props;
  
  return (    
    <div className={styles.UserItem}>
      <div className={styles.UserItemInfo}>
        <span > {user.firstname} {user.lastname}</span>
          <span > {user.email}</span>
        </div>           
    </div>  
  )
}