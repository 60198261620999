import * as Yup from "yup";

export function initialValues(address) {
  return {
    title           : address?.title          || "",
    description     : address?.description    || "",
    state           : address?.state          || "",    
    city            : address?.city           || "",
    phone           : address?.phone          || "",
  };
}

export function validationSchema() {
  return Yup.object({
    title         : Yup.string().required(true),
    description   : Yup.string().required(true),
    state         : Yup.string().required(true),
    city          : Yup.string().required(true),
    phone         : Yup.number().required(true),
  });
}