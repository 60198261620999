import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Bag } from "../../../../api";
import { Container } from "semantic-ui-react";
import { Separator, NoResult, GridGames, Seo } from "../../../../components/Shared";
import { size } from "lodash";

const bagCtrl = new Bag();

export function Item(props) {
    const [ data, setData ] = useState({});
    let location = useLocation();
    let navigate = useNavigate();
    let arrayLocation = location.pathname.split("/");
    const hasProducts = size(data) > 0;
    const [ title, setTitle ] = useState("");

    useEffect(() => {
        (async () => {
          try {
            if(arrayLocation.length >= 3 || arrayLocation[2] !== "" ){
                if(arrayLocation[2] === "stock"){
                    const response = await bagCtrl.getBagsByStock(true);
                    const arrayBags = [...response.bags.docs];

                    for (let index = 0; index <arrayBags.length; index++) {
                        const arrayFeatures = arrayBags[index].features.split(",");
                        const arrayItems = arrayBags[index].items.split(",");
                        response.bags.docs[index].features = [];
                        response.bags.docs[index].items = [];
                        var arrayFeaturesTemp = [];
                        var arrayItemsTemp = [];

                        for (let index = 0; index < arrayFeatures.length; index++) {
                            const element = arrayFeatures[index];
                            arrayFeaturesTemp.push(element);
                        }

                        for (let index = 0; index < arrayItems.length; index++) {
                            const element = arrayItems[index];
                            arrayItemsTemp.push(element);
                        }
                        response.bags.docs[index].features = arrayFeaturesTemp;
                        response.bags.docs[index].items = arrayItemsTemp;
                    }
                    setData(response.bags.docs);
                    setTitle("Disponibles")
                }else{
                    const response = await bagCtrl.getBagsByStock(false);
                    const arrayBags = [...response.bags.docs];

                    for (let index = 0; index <arrayBags.length; index++) {
                        const arrayFeatures = arrayBags[index].features.split(",");
                        const arrayItems = arrayBags[index].items.split(",");
                        response.bags.docs[index].features = [];
                        response.bags.docs[index].items = [];
                        var arrayFeaturesTemp = [];
                        var arrayItemsTemp = [];

                        for (let index = 0; index < arrayFeatures.length; index++) {
                            const element = arrayFeatures[index];
                            arrayFeaturesTemp.push(element);
                        }

                        for (let index = 0; index < arrayItems.length; index++) {
                            const element = arrayItems[index];
                            arrayItemsTemp.push(element);
                        }
                        response.bags.docs[index].features = arrayFeaturesTemp;
                        response.bags.docs[index].items = arrayItemsTemp;
                    }
                    setData(response.bags.docs);
                    setTitle("Bajo Pedido")
                }
            }
          }catch (error) {
            console.error(error);
          }
        })();
    }, [location]);
      
    if(arrayLocation.length < 3 || arrayLocation[2] === "" ){
          navigate("/");
          return null;
    }

    return(
        <>
        <Seo  title={`${title}`} />
            <Container>
            <Separator height={50} />

            <h2>{title}</h2>

            {hasProducts ? (
                <>
                <GridGames games={data} />
                <Separator height={30} />                
                </>
            ) : (
                <NoResult
                text={`La categoria ${title} aun no tiene productos`}
                />
            )}

            <Separator height={100} />
            </Container>            
        </>
    )
}