import { ENV, authFetch } from "../utils";

export class Bag {

  async createBag(data){
    try {
      const formData = new FormData();

      Object.keys(data).forEach((key) =>{
          formData.append(key, data[key]);
      });

      if(data.wallpaper){
          formData.append("wallpaper", data.wallpaper[0]);
      }

      if(data.photos){
        data.photos.forEach((imagen) => {
          formData.append("photos", imagen);
        });
      }

      const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAG}`;
      const params = {
          method  :   "POST",          
          body : formData
      };
      
      const response  = await authFetch(url, params);            
      const result    = await response.json();

      if(response.status !== 200 ) throw result;
      
      return result;


    } catch (error) {
      throw error
    }
  }

    async getBagsByStock(flgStock) {
      try {
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAGBYSTOCK}/${flgStock}`;
    
        const response = await fetch(url);
        const result = await response.json();
    
        if (response.status !== 200) throw result;
    
        return result;
      } catch (error) {
        throw error;
      }
    }

    async getBagById(id) {
      try {
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAGBYID}/${id}`;
    
        const response = await fetch(url);
        const result = await response.json();
    
        if (response.status !== 200) throw result;
    
        return result;
      } catch (error) {
        throw error;
      }
    }

    async getLastPublished(){
      try {
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAGLAST}`;
    
        const response = await fetch(url);
        const result = await response.json();
    
        if (response.status !== 200) throw result;
    
        return result;
      } catch (error) {
        throw error;
      }
    }

    async getLatestPublished({ limit = 10}) {
      try {
  
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAGSLAST}?limit=${limit}`;
  
        const response = await fetch(url);
        const result = await response.json();
  
        if (response.status !== 200) throw result;
  
        return result.bags;
      } catch (error) {
        throw error;
      }
    }

    async getBags(page =1, limit = 10){
      try {
        const pageFilter    = `page=${page}`;
        const limitFilter   = `limit=${limit}`;
      
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAG}?${pageFilter}&${limitFilter}`;
        const response = await authFetch(url);
        const result = await response.json();
  
        if (response.status !== 200) throw result;
  
        return result.bags;
      } catch (error) {
        throw error;
      }
    }

    async updateBag(idBag, data){
      try {
        const formData = new FormData();
  
        Object.keys(data).forEach((key) =>{
            formData.append(key, data[key]);
        });
  
        if(data.wallpaper){
            formData.append("wallpaper", data.wallpaper[0]);
        }
  
        if(data.photos){
          data.photos.forEach((imagen) => {
            formData.append("photos", imagen);
        });
      }
  
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAG}/${idBag}`;
        const params = {
            method  :   "PATCH",          
            body : formData
        };
        
        const response  = await authFetch(url, params);            
        const result    = await response.json();
  
        if(response.status !== 200 ) throw result;
        
        return result;  
  
      } catch (error) {
        throw error
      }
    }    

    async deleteBag(idBag){
      try {
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAG}/${idBag}`;
        const params = {
            method  :   "DELETE",
        };

        const response  = await authFetch(url, params);            
        const result    = await response.json();

        if(response.status !== 200 ) throw result;

        return result;
      } catch (error) {
        throw error;
      }
    }

    async updateStockBag(idBag, data){
      try {
      
        const formData = new FormData();
        formData.append("flgStock", data);

        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.BAGUPDATESTOCK}/${idBag}`;
        const params = {
            method  :"PATCH",          
            headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify(data)
        };
        
        const response  = await authFetch(url, params);            
        const result    = await response.json();
  
        if(response.status !== 200 ) throw result;
        
        return result;  
  
      } catch (error) {
        throw error
      }
    }
}