import { useLocation } from "react-router-dom";
import { Seo, Separator } from "../../../components/Shared";
import { Game } from "../../../components/Game";
import { useEffect, useState } from "react";
import { Bag } from "../../../api";

const BagCtrl = new Bag(); 

export function BagPage() {
  const [ bag, setBag ] = useState(null)
  let location = useLocation();
  let arrayLocation = location.pathname.split("/");

  useEffect(() => {
    (async () => {
      try {
        if(arrayLocation.length === 3){
          const response = await BagCtrl.getBagById(arrayLocation[2]);
          const arrayBag = response.bag;
          const arrayFeatures = arrayBag.features.split(",");
          const arrayItems = arrayBag.items.split(",");
          response.bag.features = [];
          response.bag.items = [];
          var arrayFeaturesTemp = [];
          var arrayItemsTemp = [];

          for (let index = 0; index < arrayFeatures.length; index++) {
              const element = arrayFeatures[index];
              arrayFeaturesTemp.push(element);
          }

          for (let index = 0; index < arrayItems.length; index++) {
              const element = arrayItems[index];
              arrayItemsTemp.push(element);
          }
          response.bag.features = arrayFeaturesTemp;
          response.bag.items = arrayItemsTemp;

          setBag(response.bag)
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  if(!bag){
    return null
  }

  return (
    <>
    <Seo title={`${bag.trademark.value} - ${bag.line}`}/>
      <Separator height={200} />
        <Game.Panel bagId={bag._id} bag={bag} />
        <Separator height={50} />
        <Game.Media
          video={bag?.video}
          screenshots={bag.photos}
          folder={bag.folder}
        />
        <Separator height={50} />
    </>
  );
}