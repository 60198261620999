import { useState } from "react";
import { Tab } from "semantic-ui-react";
import { useAuth } from "../../../hooks";
import {
  Info,
  Settings,
  Address,
  Wishlist
} from "../../../components/Account";
import { Separator, Seo } from "../../../components/Shared";
import { useNavigate } from "react-router-dom";

import styles from "./Account.module.scss";

export function Account() {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [reload, setReload] = useState(false);

  if (!user) {
    navigate("/join/sign-in")
    return null;
  }

  const onReload = () => setReload((prevState) => !prevState);

  const panes = [
    {
      menuItem: "Lista de deseos",
      render: () => (
        <Tab.Pane attached={false}>
          <Wishlist />
          <Separator height={80} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Direcciones",
      render: () => (
        <Tab.Pane attached={false}>
          <Address.AddAddress onReload={onReload} />
          <Address.ListAddresses reload={reload} onReload={onReload} />
          <Separator height={80} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: { key: 20, icon: "settings", content: "Ajustes" },
      render: () => (
        <Tab.Pane attached={false} key={99}>
          <Settings.ChangeNameForm />
          <div className={styles.containerForms}>
            <Settings.ChangeEmailForm />
            <Settings.ChangePasswordForm />
          </div>
          <Separator height={80} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 21,
        icon: "log out",
        content: "",
        onClick: logout,
      },
    },
  ];

  return (
    <>
        <Seo title="Mi cuenta" />
        <Info />
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={panes}
          className={styles.tabs}
        />
    </>
    
  );
}
