import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

import styles from "./ClassificationFormList.module.scss";

export function ClassificationFormList(props) {
  const { classification, fnSetValues } = props;
  
  return (
    <div className={styles.SchemaItem}>
      <div className={styles.SchemaItemInfo}>
        <span > {classification.value}</span>
        <span > {classification.id}</span>
      </div>
      <div className={styles.SchemaItemActions}>
        <Button type='button' icon color='red' onClick={ (e) => fnSetValues(classification)}>
          <Icon name='pencil'/>
        </Button>
      </div>
    </div>
  )
}
