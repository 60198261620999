import { Helmet } from "react-helmet";

export function Seo(props) {
  const {
    title = "LUX & GLAM",
    description = " Catálogo de Productos",
  } = props;

  return (
    <Helmet >
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet >
  );
}