import React, { useEffect, useState } from 'react';
import { Bag } from "../../../../api";
import { BagItem } from "../BagItem";
import { Loader, Pagination } from 'semantic-ui-react';
import { map, size } from 'lodash';

import styles from "./BagList.module.scss";

const BagController = new Bag();

export function BagList(props) {
    const { onReload, reload, trademark } = props;
    const [ bags, setBags ] = useState(null);
    const [ pagination, setPagination ] = useState(null);
    const [ page, setPage ] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                setBags(null);
                const response = await BagController.getBags(page);
                setBags(response.docs);
                setPagination({
                    limit       :       response.limit,
                    page        :       response.page,
                    pages       :       response.pages,
                    total       :       response.total
                })            
            } catch (error) {
                console.error(error);
           }           
        })()

    },[page, reload]);

    const changePage = (_,data) => {
        setPage(data.activePage);
    }

    if(!bags) return <Loader active inline="centered"/>
    if(size(bags) === 0 ) return "No hay posts"; 


    return (
        <div className={styles.ListPost}>
            {
                map(bags, (bag) => (
                    <BagItem trademark={trademark} onReload={onReload} key={bag._id} bag={bag} />
                ))
            }
        
            <div className={styles.ListPostPagination}>
                <Pagination totalPages={pagination.pages} defaultActivePage={pagination.page} ellipsisItem={null} firstItem={null} lastItem={null} onPageChange={changePage}/>
            </div>
        </div>
    )
}