import { Form } from "semantic-ui-react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { initialValues, validationSchema } from "./RegiterForm.form";
import { Auth } from "../../../api";

const authCtrl = new Auth();

export function RegisterForm() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues       :   initialValues(),
    validationSchema    :   validationSchema(),
    validateOnChange    :   false,
    onSubmit            :   async (formValue) => { 
      try {
        await authCtrl.register(formValue);
        navigate("/join/sign-in")
      }catch (error) {
        console.error(error);
      }
    }
  })

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group widths="equal">
        <Form.Input name="firstname" type="text" placeholder="Nombre" value={formik.values.firstname} onChange={formik.handleChange} error={formik.errors.firstname} />
        <Form.Input name="lastname" type="text" placeholder="Apellido" value={formik.values.lastname} onChange={formik.handleChange} error={formik.errors.lastname} />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input name="email" type="text" placeholder="Correo Electrónico" value={formik.values.email} onChange={formik.handleChange} error={formik.errors.email} />
        <Form.Input name="number" type="number" placeholder="Número Teléfono" value={formik.values.number} onChange={formik.handleChange} error={formik.errors.number} />
      </Form.Group>

      <Form.Group widths="equal">
        <Form.Input name="password" type="password" placeholder="Contraseña" value={formik.values.password} onChange={formik.handleChange} error={formik.errors.password} />
      </Form.Group>

      <Form.Button type="submit" fluid loading={formik.isSubmitting} > Registrarse </Form.Button>

    </Form>
  )
}