import { ENV, authFetch } from "../utils";

export class User {    
    async getMe() {
      try {
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.USER_ME}`;
    
        const response = await authFetch(url);
        const result = await response.json();
    
        if (response.status !== 200) throw result;
    
        return result;
      } catch (error) {
        throw error;
      }
    }

    async createUser(data){
        try {
            const formData = new FormData();
            Object.keys(data).forEach((key) =>{
                formData.append(key, data[key]);
            });

            if(data.fileAvatar){
                formData.append("avatar", data.fileAvatar);
            }

            const url = `${this.baseApi}/${ENV.ENDPOINTS.USER}`;
            const params = {
                method  :   "POST",
                headers :   {
                },
                body : formData
            };
            
            const response = await authFetch(url, params);
            const result    = await response.json();

            if(response.status !== 201 ) throw result;
            
            return result;
                    
        } catch (error) {
            throw error            
        }
    }

    async updateMe(userId, data) {
        try {
          const url = `${ENV.API_URL}/${ENV.ENDPOINTS.USERS}/${userId}`;
    
          const params = {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          };
    
          const response = await authFetch(url, params);
          const result = await response.json();
    
          if (response.status !== 200) throw result;
    
          return result;
        } catch (error) {
          throw error;
        }
    }

    async getUsers(page =1, limit = 10){
      try {
        const pageFilter    = `page=${page}`;
        const limitFilter   = `limit=${limit}`;
      
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.USERS}?${pageFilter}&${limitFilter}`;
        const response = await authFetch(url);
        const result = await response.json();
  
        if (response.status !== 200) throw result;
  
        return result.users;
      } catch (error) {
        throw error;
      }
    } 


}