
import { Bag } from "../../../api"
import { useEffect, useState } from "react";
import { GridGames } from "../../../components/Shared";

const bagCtrl = new Bag();

export  function LastestGames(props) {
  const { title, limit= 10} = props;
  const [ games, setGames ] = useState(null);

  useEffect(() => {
    (
      async () => {
        try {
          const response = await bagCtrl.getLatestPublished({limit});
          setGames(response.docs);
        } catch (error) {
          console.error(error);
        }
      }
    )()
  },[])

  if(!games) return null;

  return (
    <div>
      <h2 style={{ paddingLeft: "10px" }}>{title}</h2>
        <GridGames games={games}/>
    </div>
  )
}