import { ENV, authFetch } from "../utils";

export class Classification {
    async addClassification(data){
        try {        
          const url = `${ENV.API_URL}/${ENV.ENDPOINTS.CLASSIFICATION}`;
          const params = {
            method  :   "POST",
            headers :   {
                "Content-Type": "application/json",
            },
            body : JSON.stringify(data),
        };

        const response  = await authFetch(url, params);            
        const result    = await response.json();

        if(response.status !== 200 ) throw result;

        return result;
            
        } catch (error) {
          throw error;
        }
    }
}