import { Link } from "react-router-dom";
import { RegisterForm } from "../../../../components/Auth";
import { Seo } from "../../../../components/Shared/Seo"

import styles from "./sign-up.module.scss";

export function SignUpPage() {
  return (
    <>
      <Seo title="Registrarse" />
      <div className={styles.signUp}>
        <h3>Crear cuenta</h3>
        <RegisterForm />
        <div className={styles.actions}>
          <Link to="/join/sign-in">Atras</Link>
        </div>
      </div>
    </>
  )
}