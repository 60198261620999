import { useState } from "react";
import { Icon, Grid} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { map } from "lodash";
import classNames from "classnames";
import { data } from "./Menu.data";

import styles from "./Menu.module.scss";

export function Menu(props) {
  const { isOpenSearch } = props;
  const [ showSearch, setShowSearch ] = useState(isOpenSearch);

  const openCloseSearch = () => setShowSearch((prevState) => !prevState);

  return (
    <div className={styles.platforms}>
        <Link key={1} to={"/"}>
            <Icon name="home" /> Inicio
        </Link>
     
     {map(data, (item) => (
        <Link key={item.title} to={`${item.url}`}>
          <Icon name={item.icon} />
          {item.title}
        </Link>
      ))}

      <div
        className={classNames(styles.inputContainer, {
          [styles.active]: showSearch,
        })}
      >
        
        <Icon
          name="close"
          className={styles.closeInput}
          onClick={openCloseSearch}
        />
      </div>
    </div>
  );
}