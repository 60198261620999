import React from 'react';
import { BrowserRouter } from "react-router-dom";
import { WebRouter, AdminRouter } from "./router";
import { AuthProvider } from "./contexts";

import "semantic-ui-css/semantic.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../src/scss/global.scss";


export default function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <WebRouter/>
        <AdminRouter/>
      </BrowserRouter>
    </AuthProvider>
  )
}