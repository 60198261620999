import { Button, Container, Image, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

import styles from "./BannerAd.module.scss";

export function BannerAd(props) {
  const { title, subtitle, btnTitle, btnLink, image } = props;

  return (
    <Grid>
      <Grid.Column only="computer" computer={16}>
        <div className={styles.container}>
          <Container className={styles.containerImage}>
            <Image src={image} />
          </Container>

          <div className={styles.infoContainer}>
            <Container>
              <h2>{title}</h2>
              <h3>{subtitle}</h3>

              <Button as={Link} to={btnLink} primary>
                {btnTitle}
              </Button>
            </Container>
          </div>
        </div>
    </Grid.Column>
    <Grid.Column only="mobile tablet" mobile={16} tablet={16}>
        <div className={styles.container}>
          <Container className={styles.containerImageMT}>
            <Image src={image} />
          </Container>

          <div className={styles.infoContainerMT}>
            <Container>
              <h2>{title}</h2>
              <h3>{subtitle}</h3>

              <Button as={Link} to={btnLink} primary>
                {btnTitle}
              </Button>
            </Container>
          </div>
        </div>
    </Grid.Column>
    </Grid>
  );
}