import * as Yup from "yup";

export function initialValues(){
    return {
        email       :   "",
        firstname   :   "",
        lastname    :   "",
        number      :   "",
        password    :   ""
    };
};

export function validationSchema() {
    return Yup.object({
        email       :   Yup.string().email(true).required(true),
        firstname   :   Yup.string().required(true),
        lastname    :   Yup.string().required(true),
        number      :   Yup.number().required(true),
        password    :   Yup.string().required(true),
    })
}