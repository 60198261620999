import React, { useState } from 'react';
import { Button, Icon, Confirm } from 'semantic-ui-react';
import { BasicModal } from '../../../Shared';
import { Schema } from "../../../../api";
import { SchemaForm } from '../SchemaForm';

import styles from "./SchemaItem.module.scss";

const SchemaController = new Schema();

export function SchemaItem(props) {
    const { onReload, schema } = props;
    const [ showModal, setShowModal ] = useState(false);
    const [ showConfirm, setShowConfirm ] = useState(false);    

    const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

    const onDelete = async () => {
        try {
            await SchemaController.deleteSchema(schema._id);
            onReload();
            onOpenCloseConfirm();
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <>
            <div className={styles.SchemaItem}>
                <div className={styles.SchemaItemInfo}>
                    <span > {schema.name}</span>
                    <span > {schema.description}</span>
                </div>
                <div className={styles.SchemaItemActions}>
               
                <Button icon primary onClick={onOpenCloseModal}>
                    <Icon name='pencil'/>
                </Button>
                <Button icon color='red' onClick={onOpenCloseConfirm}>
                    <Icon name='trash'/>
                </Button>
                </div>
            </div>
            <BasicModal show={showModal} onClose={onOpenCloseModal} title="Editar Esquema">
                <SchemaForm onClose={onOpenCloseModal} onReload={onReload} schema={schema}/>
            </BasicModal>

            <Confirm open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={onDelete} content={`Eliminar ${schema.name}`} size='mini'/>
        </>
    )
}

