export const features = [
    { key: '1', text: 'Hilo bordado',                               value: 'Hilo bordado', style: { color: 'red' }  },
    { key: '2', text: 'Costuras a mano',                            value: 'Costuras a mano' },
    { key: '3', text: 'Herrajes en metal dorado',                   value: 'Herrajes en metal dorado' },
    { key: '4', text: 'Código interno',                             value: 'Código interno' },
    { key: '5', text: 'Strap en hilo bordado incluído',             value: 'Strap en hilo bordado incluído' },
    { key: '6', text: 'Calfskin',                                   value: 'Calfskin' },
    { key: '7', text: 'Lambskin',                                   value: 'Lambskin' },
    { key: '8', text: 'Código grabado en plaqueta Chanel dentro',   value: 'Código grabado en plaqueta Chanel dentro ' },
    { key: '9', text: 'Piel de cordero acolchada',                  value: 'Piel de cordero acolchada' },
    { key: '10', text: 'Canvas',                                    value: 'Canvas' },
    { key: '11', text: 'Herrajes en metal plateado',                value: 'Herrajes en metal plateado' },
    { key: '12', text: 'Reversible',                                value: 'Reversible' },
    { key: '13', text: 'Suede',                                     value: 'Suede' },
    { key: '14', text: 'Tweed',                                     value: 'Tweed' },
    { key: '15', text: 'Denim',                                     value: 'Denim' },
    { key: '16', text: 'Monogram',                                  value: 'Monogram'},
    { key: '17', text: 'Natural Wicker',                            value: 'Natural Wicker'},
    { key: '18', text: 'Quikted-Leather',                           value: 'Quikted-Leather'}
]

export const items = [
    { key: '1', text: 'Caja',                                       value: 'Caja' },
    { key: '2', text: 'Dust bag',                                   value: 'Dust bag' },
    { key: '3', text: 'Etiquetas',                                  value: 'Etiquetas' },
    { key: '4', text: 'Tarjeta',                                    value: 'Tarjeta' },
    { key: '5', text: 'Shopping bag',                               value: 'Shopping bag' },
]