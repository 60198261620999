import { useState } from "react";
import { Image, Grid } from "semantic-ui-react";
import { map } from "lodash";
import Slider from "react-slick";
import { FullModal } from "../../../../components/Shared";
import { ENV } from "../../../../utils";

import styles from "./Gallery.module.scss";

export function Gallery(props) {
  const { screenshots } = props;
  const [ show, setShow ] = useState(false);

  const onOpenClose = () => setShow((prevState) => !prevState);

  const screenshotsClone = screenshots.split(",");
  const principalImage = screenshotsClone.shift();
  let screenshotsArray = screenshots.split(",");

  const settings = {
    dots: true,
    dotsClass: styles.dots,
    infinite: true,
    slidersToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    customPaging: function (index) {
      return <Image src={`${ENV.SERVER_HOST}/${screenshotsArray[index]}`} />;
    },
  };

  return (
    <>
      <Grid>
        <Grid.Column only='computer' computer={16} >
          <div className={styles.gallery}>
            <div className={styles.principal}>
              <Image src={`${ENV.SERVER_HOST}/${principalImage}`} onClick={onOpenClose} />
            </div>
            <div className={styles.grid}>
              {map(screenshotsClone, (screenshot) => (
                <div key={screenshot}>
                  <Image src={`${ENV.SERVER_HOST}/${screenshot}`} onClick={onOpenClose} />
                </div>
              ))}
            </div>
          </div>
        </Grid.Column>
        
        <Grid.Column only='mobile tablet' mobile={16} tablet={16} >
          <div className={styles.gallery}>
            <div className={styles.principalMT}>
            <Image src={`${ENV.SERVER_HOST}/${principalImage}`} onClick={onOpenClose} />
            </div>

            <div className={styles.gridTM}>
              {map(screenshotsClone, (screenshot) => (
                <div key={screenshot}>
                  <Image src={`${ENV.SERVER_HOST}/${screenshot}`} onClick={onOpenClose} />
                </div>
              ))}
            </div>
          </div>
        </Grid.Column>
      </Grid>

      <FullModal show={show} onClose={onOpenClose}>
        <Grid>
          <Grid.Column only='computer' computer={16} >
          <div className={styles.carouselContainer}>
            <Slider {...settings}>
              {map(screenshotsArray, (screenshot, id) => (
                <div key={id}>
                  <Image src={`${ENV.SERVER_HOST}/${screenshot}`} />
                </div>
              ))}
            </Slider>
          </div>
          </Grid.Column>
      
          <Grid.Column only='mobile tablet' mobile={16} tablet={16} >
          <div className={styles.carouselContainerMT}>
            <Slider {...settings}>
              {map(screenshotsArray, (screenshot, id) => (
                <div key={id}>
                  <Image src={`${ENV.SERVER_HOST}/${screenshot}`} />
                </div>
              ))}
            </Slider>
          </div>
          </Grid.Column>
        </Grid>
      </FullModal>
    </>
  );
}