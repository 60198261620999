import React, { useState, useEffect } from 'react';
import { Tab, Button } from 'semantic-ui-react';
import { Seo, BasicModal } from '../../../components/Shared';
import { BagList, BagForm } from "../../../components/Admin/Bag";
import { Trademark } from '../../../api';

import styles from "./Bag.module.scss";

const TrademarkController = new Trademark();
export function Bag() {
    const [ showModal, setShowModal] = useState(false);
    const [ reload, setReload] = useState(false);
    const [ trademark, setTrademark] = useState([]);

    const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
    const onReload = () => setReload((prevState) => !prevState);

    useEffect(() => {
        (async () => {    
            try {
                const response = await TrademarkController.getTrademark();

                var arrayTrademark = [];
                for (let index = 0; index < response.trademark.length; index++) {
                    var objtmp = {
                        key     :   response.trademark[index]._id,
                        text    :   response.trademark[index].value,
                        value   :   response.trademark[index].id
                    };
                    arrayTrademark.push(objtmp)
                    
                }
                setTrademark(arrayTrademark);
            } catch (error) {
                console.error(error);
            }
        })();
    }, [reload]);

    return (
        <div className={styles.bag}>
            <Seo title="Listado de Bolsas"/>
            <div className={styles.blogPage}>                
                <div className={styles.blogPageAdd}>
                    <div className={styles.titleLeft}><h1> Listado de Bolsas</h1></div>
                    <div className={styles.titleRight}> <Button primary onClick={onOpenCloseModal}> Nueva Bolsa</Button> </div>                    
                </div>
                <Tab.Pane attached={false}>
                    <BagList trademark={trademark} onReload={onReload} reload={reload}/>
                </Tab.Pane>
            </div>
            <BasicModal show={showModal} onClose={onOpenCloseModal} title="Crear Bolsa">
                <BagForm trademark={trademark} onClose={onOpenCloseModal} onReload={onReload}/>
            </BasicModal>
        </div>
    )
}