import { Link } from "react-router-dom";
import { map } from "lodash";
import { Label, WishlistIcon } from "../../../../components/Shared";
import { ENV } from "../../../../utils";

import styles from "./GridGames.module.scss";

export function GridGames(props) {
  const { wishlist, onReload } = props;

  return (
    <div className={styles.gridGames}>
      {map(wishlist, (item) => {
        return (
          <div key={item.id} className={styles.game}>
            <Link key={item.bag._id} to={`/bag/${item.bag._id}`} className={styles.game}>
          <div>
            <img src={`${ENV.SERVER_HOST}/${item.bag.wallpaper}`}/>
            {
              item.discount > 0 && (
                <Label.Discount className={styles.discount}>
                  {`-${item.bag.discount}%`}
                </Label.Discount>
              )
            }
          </div>
          <div>
            <span>{item.bag.trademark.value} - {item.bag.line} </span>            
          </div>
        </Link>
            <WishlistIcon
              bagId={item.bag._id}
              className={styles.whislistIcon}
              removeCallback={onReload}
            />
          </div>
        );
      })}
    </div>
  );
}