import { Link } from "react-router-dom";
import { map } from "lodash";
import { Label } from "../../../components/Shared";
import { Grid } from "semantic-ui-react";
import { ENV } from "../../../utils";

import styles from "./GridGames.module.scss";

export function GridGames(props) {
  const { games } = props;  
  return (
    <Grid>
      <Grid.Column only="computer" computer={16}>
        <div className={styles.gridGames}>
          {map(games, (game) =>(
            <Link key={game._id} to={`/bag/${game._id}`} className={styles.game}>
              <div>
                <img src={`${ENV.SERVER_HOST}/${game.wallpaper}`}/>
                {
                  game.discount > 0 && (
                    <Label.Discount className={styles.discount}>
                      {`-${game.discount}%`}
                    </Label.Discount>
                  )
                }
              </div>
              <div>
                <span>{game.trademark.value} - {game.line} </span>            
              </div>
            </Link>
          ))
          }
        </div>
     </Grid.Column>
     <Grid.Column only='mobile tablet' mobile={16} tablet={16}>
        <div className={styles.gridGamesMT}>
          {map(games, (game) =>(
            <Link key={game._id} to={`/bag/${game._id}`} className={styles.gameM}>
              <div>
                <img src={`${ENV.SERVER_HOST}/${game.wallpaper}`}/>
                {
                  game.discount > 0 && (
                    <Label.Discount className={styles.discount}>
                      {`-${game.discount}%`}
                    </Label.Discount>
                  )
                }
              </div>
              <div>
                <span>{game.trademark.value} - {game.line} </span>            
              </div>
            </Link>
          ))
          }
        </div>
     </Grid.Column>
    </Grid>
  )
}