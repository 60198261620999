import React, { useState } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { BasicModal } from '../../../Shared';
import { ClassificationForm } from '../ClassificationForm';

import styles from "./SchemaItem.module.scss";

export function SchemaItem(props) {
    const { onReload, schema } = props;
    const [ showModal, setShowModal ] = useState(false);

    const onOpenCloseModal = () => setShowModal((prevState) => !prevState);

    return (
        <>
            <div className={styles.SchemaItem}>
                <div className={styles.SchemaItemInfo}>
                    <span > {schema.name}</span>
                    <span > {schema.description}</span>
                </div>
                <div className={styles.SchemaItemActions}>
                    <Button icon primary onClick={onOpenCloseModal}>
                        <Icon name='pencil'/>
                    </Button>
                </div>
            </div>
            <BasicModal show={showModal} onClose={onOpenCloseModal} title="Editar Esquema">
                <ClassificationForm onClose={onOpenCloseModal} onReload={onReload} schema={schema}/>
            </BasicModal>
        </>
    )
}
