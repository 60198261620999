import React, { useState, useEffect } from 'react';
import { User } from '../../../../api';
import { map, size } from 'lodash';
import { Loader, Pagination } from 'semantic-ui-react';
import { UserItem } from './UserItem';

import styles from "./UserList.module.scss";

const UserController = new User();

export function UserList() {
  const [ users, setUsers] = useState([]);
  const [ page, setPage ] = useState(1);
  const [ pagination, setPagination ] = useState(null);

  useEffect(() => {
    (async () => {    
        try {
          setUsers(null);
          const response = await UserController.getUsers(page);
          setUsers(response.docs);
          setPagination({
              limit       :       response.limit,
              page        :       response.page,
              pages       :       response.pages,
              total       :       response.total
          })
        } catch (error) {
            console.error(error);
        }
    })();
  }, [page]);

  const changePage = (_,data) => {
  setPage(data.activePage);
  }

  if(!users) return <Loader active inline="centered"/>
  if(size(users) === 0 ) return "No hay usurios"; 

  return (
    <div className={styles.ListUser}>
    {
        map(users, (user) => (
            <UserItem key={user._id} user={user} />
        ))
    }

    <div className={styles.UserPostPagination}>
        <Pagination totalPages={pagination.pages} defaultActivePage={pagination.page} ellipsisItem={null} firstItem={null} lastItem={null} onPageChange={changePage}/>
    </div>
</div>
  )
}
