import { useState, useEffect } from "react";
import { Icon } from "semantic-ui-react";
import classNames from "classnames";
import { Wishlist } from "../../../api";
import { useAuth } from "../../../hooks";
import { useNavigate } from "react-router-dom";

import styles from "./WishlistIcon.module.scss";

const wishlistCtrl = new Wishlist();

export function WishlistIcon(props) {
  const { bagId, className, removeCallback } = props;
  const [ hasWishlist, setHasWishlist] = useState(null);
  const { user } = useAuth();
  const navigate = useNavigate();


  useEffect(() => {
    (async () => {
      try {
        const response = await wishlistCtrl.check(user ? user.response._id : null, bagId);
        setHasWishlist(response);
      } catch (error) {
        setHasWishlist(false);
        console.error(error);
      }
    })();
  }, [bagId]);

  const addWishlist = async () => {
    if(!user){
      navigate("/join/sign-in")
      return null;
    }

    const response = await wishlistCtrl.add(user.response._id, bagId);
    setHasWishlist(response);
  };

  const deleteWishlist = async () => {
    try {

      if(!user){
        navigate("/join/sign-in")
        return null;
      }
      await wishlistCtrl.delete(hasWishlist._id);
      setHasWishlist(false);

      if (removeCallback) {
        removeCallback();
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (hasWishlist === null) return null;

  return (
    <Icon
      name={hasWishlist ? "heart" : "heart outline"}
      onClick={hasWishlist ? deleteWishlist : addWishlist}
      className={classNames(styles.wishlistIcon, {
        [className]: className,
      })}
    />
  );
}