import React from 'react';
import { Seo } from '../../../components/Shared';
import  { Schema } from '../../../components/Admin/Schema';

export function SchemaPage() {
    return (
        <>
            <Seo title="Admin Esquemas LUX & GLAM"/>
            <Schema/>
        </>
    )
}