import React, { useEffect, useState } from 'react';
import { Form, Checkbox, Image  } from 'semantic-ui-react';
import { useFormik } from 'formik';
import { features, items } from "./BagForm.data"
import { initialValues, validationSchema } from "./BagForm.form" ;
import { Bag } from '../../../../api';
import { ENV } from '../../../../utils';

import styles from "./BagForm.module.scss";

const BagController = new Bag();

export function BagForm(props) {
    const { bag, onClose, onReload, trademark } = props;
    const [ imagesWallpaper, setImagesWallpaper ] = useState([]);
    const [ imagesPhotos, setImagesPhotos ] = useState([]);
    const [ currentFetures, setCurrentFeatures ]   = useState([]);
    const [ currentItems, setCurrentItems ] = useState([]);
    const [ wallpaper, setwallpaper] = useState([]);
    const [ photos, setPhotos] = useState([]);
    const [ flgModWallpaper, setflgModWallpaper] = useState(false);
    const [ flgModPhotos, setflgModPhotos] = useState(false);

    useEffect(() => {
        handleSubmit();
    },[imagesWallpaper, imagesPhotos]);

    useEffect(() => {
        if(bag){
            var arrayPhotos     =   bag.photos.split(",");
            var arrayFeatures   =   bag.features.split(",");
            var arrayItems      =   bag.items.split(",");
            var objTmp = [];

            setwallpaper(`${ENV.SERVER_HOST}/${bag.wallpaper}`);
                                            
            for (let index = 0; index < arrayPhotos.length; index++) {
                objTmp.push(`${ENV.SERVER_HOST}/${arrayPhotos[index]}`);
            }
            setPhotos(objTmp);

            objTmp= [];
            for (let index = 0; index < arrayFeatures.length; index++) {
                objTmp.push(arrayFeatures[index]);
            }
            setCurrentFeatures(objTmp);

            objTmp= [];
            for (let index = 0; index < arrayItems.length; index++) {
                objTmp.push(arrayItems[index]);
            }
            setCurrentItems(objTmp);
        }
    },[]);

    const handleChangeFeatures = (e, { value }) => {
        setCurrentFeatures(value);
        formik.setFieldValue("features", value);
    }

    const handleChangeItems = (e, { value }) => {
        setCurrentItems(value);
        formik.setFieldValue("items", value);
    }

    const handleSelectionWallpaper = (event) => {
        const files = event.target.files;
        const nuevasImagenes = [];
    
        for (let i = 0; i < files.length; i++) {
          const imagen = files[i];
          nuevasImagenes.push(imagen);
        }        
        setflgModWallpaper(true);
        setImagesWallpaper(nuevasImagenes);
    };

    const handleSelectionPhotos = (event) => {
        const files = event.target.files;
        const nuevasImagenes = [];
    
        for (let i = 0; i < files.length; i++) {
          const imagen = files[i];
          nuevasImagenes.push(imagen);
        }
        setflgModPhotos(true);
        setImagesPhotos(nuevasImagenes);
    };
    
    const handleSubmit = async () => {
        formik.setFieldValue("wallpaper", imagesWallpaper);

        let array = [];
        imagesPhotos.forEach((imagen) => {
            array.push(imagen);
        });
        formik.setFieldValue("photos", array);
    };
    
    const formik = useFormik({
        initialValues       :   initialValues(bag),
        validationSchema    :   validationSchema(),
        validateOnChange    :   false,
        onSubmit : async (formValue) =>{
            try {
                if(bag){
                    if(formValue.photos && formValue.photos.length === 0){
                        delete formValue.photos;
                    }
                    if(formValue.wallpaper.length === 0){
                        delete formValue.wallpaper;
                    }
                    await BagController.updateBag(bag._id, formValue);
                }else{                    
                    await BagController.createBag(formValue);
                }
                onReload();
                onClose();                
            } catch (error) {
                console.error(error);
            }
        }
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
            <Form.Field width={3} className={styles.aa}>
                <label > Portada </label>
            </Form.Field>
            <Form.Field width={13} className={styles.bb}>
            {
                bag && !flgModWallpaper &&
                    <>
                        <label>Imágenen seleccionada:</label>
                        <div className={styles.grid}>                                            
                            <div key={wallpaper}>
                                <Image key={wallpaper} src={wallpaper} size="small" />
                            </div>                        
                        </div>
                    </>                        
            }
            <input type="file" accept="image/*" onChange={handleSelectionWallpaper} style={{color: "red"}} />
            </Form.Field>
            </Form.Group>
            <Form.Group  >
                <Form.Field width={3} className={styles.aa}>
                    <label> Fotografías </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                {
                    bag && !flgModPhotos &&
                        <>
                            <label>Imágenes seleccionadas:</label>
                            <div className={styles.grid}>
                                {   
                                    photos.map((imagen, index) => (
                                        <div key={index}>
                                            <Image key={index} src={imagen} size="small" />
                                        </div>
                                    ))
                                }
                            </div>
                        </>                           
                }
                    <input type="file" accept="image/*" multiple onChange={handleSelectionPhotos} style={{color: "red"}} />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Marca </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                    <Form.Dropdown placeholder="Seleccione Marca" options={trademark} selection onChange={(_, data) => {formik.setFieldValue("trademark", data.value)}} value={formik.values.trademark} error={formik.errors.trademark} />
                </Form.Field>                                
            </Form.Group>   
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Línea </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                    <Form.Input name="line" placeholder="Linea" onChange={formik.handleChange} value={formik.values.line} error={formik.errors.line}/>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Dimensiones </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                    <Form.Input name="dimentions" placeholder="Dimensiones" onChange={formik.handleChange} value={formik.values.dimentions} error={formik.errors.dimentions}/>
                </Form.Field>                                
            </Form.Group>   
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Descuento </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                    <Form.Input type= "number" name="discount" placeholder="Descuento" onChange={formik.handleChange} value={formik.values.discount} error={formik.errors.discount}/>
                </Form.Field>
            </Form.Group>                              
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Caracteristicas </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                    <Form.Dropdown 
                        options={features}
                        //placeholder='Caracteristicas'
                        search
                        selection
                        fluid
                        multiple
                        allowAdditions
                        value={currentFetures}
                        onChange={handleChangeFeatures}
                        defaultValue={[]}
                    />
                </Form.Field>                
            </Form.Group>
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Incluye </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb}>
                    <Form.Dropdown 
                        options={items}
                        search
                        selection
                        fluid
                        multiple
                        allowAdditions
                        value={currentItems}
                        onChange={handleChangeItems}
                        defaultValue={[]}
                        styles={{backgroundColor: "red !important"}}
                    />
                </Form.Field>                
            </Form.Group>
            <Form.Group>
                <Form.Field width={3} className={styles.aa}>
                    <label > Disponible </label>
                </Form.Field>
                <Form.Field width={13} className={styles.bb} >
                    <Checkbox size='big' name='flgstock' onChange={(_, data) => formik.setFieldValue("flgstock", data.checked)} checked={formik.values.flgstock} error={formik.errors.flgstock} />
                </Form.Field>                
            </Form.Group> 
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}>
            {bag ? "Actualziar Bolsa" : "Crear Bolsa"}
        </Form.Button>     
        </Form>
    )
}