import { ENV, authFetch } from "../utils";

export class Common {
    async getSchemas(page =1, limit = 10){
        try {
          const pageFilter    = `page=${page}`;
          const limitFilter   = `limit=${limit}`;
        
          const url = `${ENV.API_URL}/${ENV.ENDPOINTS.COMMONSCHEMA}?${pageFilter}&${limitFilter}`;
          const response = await fetch(url);
          const result = await response.json();
    
          if (response.status !== 200) throw result;
    
          return result.schema;
        } catch (error) {
          throw error;
        }
    }
}