import React from 'react';
import { Form } from 'semantic-ui-react';
import { useFormik } from 'formik';
import { initialValues, validationSchema } from "./SchemaForm.form";
import { Schema } from '../../../../api';
import { useAuth } from "../../../../hooks";

import styles from "./SchemaForm.module.scss";

const SchemaController = new Schema();

export function SchemaForm(props) {
    const { schema, onClose, onReload } = props;
    const { user } = useAuth();

    const formik = useFormik({
        initialValues       :   initialValues(schema),
        validationSchema    :   validationSchema(),
        validateOnChange    :   false,
        onSubmit : async (formValue) =>{
            try {
                if(schema){
                    formValue.modifyuser = user.response._id
                    await SchemaController.updateSchema(schema._id, formValue);
                }else{
                    formValue.createuser = user.response._id
                    await SchemaController.createSchema(formValue, user);
                }
                onReload();
                onClose();
            } catch (error) {
                    console.error(error);
            }
        }
    })

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Form.Group>
                <Form.Field width={3} className={styles.FormLeft}>
                    <label > Id </label>
                </Form.Field>
                <Form.Field width={13} className={styles.FormRight}>
                    <Form.Input name="id" placeholder="" onChange={formik.handleChange} value={formik.values.id} error={formik.errors.id}/>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field width={3} className={styles.FormLeft}>
                    <label > Nombre </label>
                </Form.Field>
                <Form.Field width={13} className={styles.FormRight}>
                    <Form.Input name="name" placeholder="" onChange={formik.handleChange} value={formik.values.name} error={formik.errors.name}/>
                </Form.Field>
                
            </Form.Group>
            <Form.Group>      
            <Form.Field width={3} className={styles.FormLeft}>
                    <label > Descripción </label>
                </Form.Field>
                <Form.Field width={13} className={styles.FormRight}>
                    <Form.Input name="description" placeholder="" onChange={formik.handleChange} value={formik.values.description} error={formik.errors.description}/>
                </Form.Field>  
            </Form.Group> 
            <Form.Button type='submit' primary fluid loading={formik.isSubmitting}> 
                {schema ? "Actualziar Esquema" : "Crear Esquema"}
            </Form.Button>
        </Form>
    )
}
