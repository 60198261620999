import { ENV, authFetch } from "../utils";

export class Trademark {
    async getTrademark() {
        try {
            const url = `${ENV.API_URL}/${ENV.ENDPOINTS.TRADEMARK}`;
        
            const response = await fetch(url);
            const result = await response.json();
        
            if (response.status !== 200) throw result;
        
            return result;
          } catch (error) {
            throw error;
          }
    }
}
