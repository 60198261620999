import { Link } from "react-router-dom";
import { LoginForm } from "../../../../components/Auth";
import { Seo } from "../../../../components/Shared/Seo"

import styles from "./sign-in.module.scss";

export function SignInPage() {
  return (
    <>
      <Seo title="Iniciar sesión" />
      <div className={styles.signIn}>
        <h3>Iniciar sesión</h3>
          <LoginForm />
        <div className={styles.actions}>
          <Link to="/join/sign-up">¿No tienes una cuenta?</Link>
        </div>
      </div>

    </>
  );
}