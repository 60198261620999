import React from 'react'
import { Logout } from "../../components/Admin/AdminLayout";
import { Image } from 'semantic-ui-react';
import { Link } from "react-router-dom";

import styles from "./AdminLayout.module.scss";

export function AdminLayout(props) {
    const { children } = props;

    return (
        <div className={styles.AdminLayout}>           
          <div className={styles.AdminLayoutRight}>
            < div className={styles.AdminLayoutHeader} >
              <div className={styles.AdminLayoutLeftHeader}>
              <Link to="/admin">
                <Image src= "/images/logo.svg" alt="MVC" />
              </Link>
              </div>
              <div className={styles.AdminLayoutRightHeader}>
                <Logout/>    
              </div>
            </div>            
          <div className={styles.AdminLayoutRightContent}> {children}</div>
          </div>
        </div>
  )
}