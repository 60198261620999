import React from 'react'
import { Routes, Route } from "react-router-dom";
import { JoinLayout, BasicLayout } from "../layouts";
import { Home } from "../pages/web/Home";
import { SignInPage } from "../pages/web/join/sign-in";
import { SignUpPage } from "../pages/web/join/sign-up";
import { Account  } from "../pages/web/Account";
import {  Item } from "../pages/web/category/Item";
import { BagPage } from "../pages/web/Bag";

export function WebRouter() {
    const loadLayout =  (Layout, Page, isContainer, relative) => {
        return (
            <Layout isContainer={isContainer} relative={relative}> 
                <Page/>
            </Layout>
        )
    }

    return (
        <Routes>
            <Route path='/' element={loadLayout(BasicLayout, Home)} />
            <Route path='/join/sign-in' element={loadLayout(JoinLayout, SignInPage)} />
            <Route path='/join/sign-up' element={loadLayout(JoinLayout, SignUpPage)} />
            <Route path='/account' element={loadLayout(BasicLayout, Account, true, true )} />
            <Route path='/category/*' element={loadLayout(BasicLayout, Item, false, true )} />
            <Route path='/bag/*' element={loadLayout(BasicLayout, BagPage, false, true )} />
        </Routes>
    )
}