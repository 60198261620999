import React, { useState } from 'react';
import { Button, Icon, Confirm } from 'semantic-ui-react';
import { BasicModal } from '../../../Shared';
import { BagForm} from "../BagForm";
import { Bag } from "../../../../api";

import styles from "./BagItem.module.scss";

const BagController = new Bag();

export function BagItem(props) {
    const { onReload, bag, trademark } = props;
    const [ showModal, setShowModal ] = useState(false);
    const [ showConfirm, setShowConfirm ] = useState(false);    
    
    const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
    const onOpenCloseConfirm = () => setShowConfirm((prevState) => !prevState);

    const onDelete = async () => {
        try {
            await BagController.deleteBag(bag._id);
            onReload();
            onOpenCloseConfirm();
        } catch (error) {
            console.error(error);
        }
    }

    const updateBagStock = async (flgstock) => {
        bag.flgstock = flgstock;
        await BagController.updateStockBag(bag._id, bag);
        onReload();
    }

    return (
    <>
        <div className={styles.BagItem}>
            <div className={styles.BagItemInfo}>
                <span > {bag.line}</span>
                <span > {bag.trademark.value}</span>
            </div>
            <div className={styles.BagItemActions}>
                {
                    bag.flgstock ? 
                    <Button icon primary onClick={ (e) => updateBagStock(false)}>
                        <Icon name='check'/>
                    </Button> 
                :
                    <Button icon primary onClick={ (e) => updateBagStock(true)}>
                        <Icon name='reply'/>
                    </Button>
                }
                <Button icon primary onClick={onOpenCloseModal}>
                    <Icon name='pencil'/>
                </Button>
                <Button icon color='red' onClick={onOpenCloseConfirm}>
                    <Icon name='trash'/>
                </Button>
            </div>
        </div>
        <BasicModal show={showModal} onClose={onOpenCloseModal} title="Editar Bolsa">
            <BagForm trademark={trademark} onClose={onOpenCloseModal} onReload={onReload} bag={bag}/>
        </BasicModal>

        <Confirm open={showConfirm} onCancel={onOpenCloseConfirm} onConfirm={onDelete} content={`Eliminar ${bag.trademark.value} - ${bag.line}`} size='mini'/>

    </>
  )
}