import * as Yup from "yup";

export function initialValues(bag){
    return{
        trademark       :       bag?.trademark ? bag.trademark.id : "",
        line            :       bag?.line || "",
        dimentions      :       bag?.dimentions || "",
        features        :       bag?.features || "",
        items           :       bag?.items || "",
        flgstock        :       bag?.flgstock === false ? false : true,
        wallpaper       :       bag?.wallpaper || "",
        photos          :       bag?.photos || "",
        discount        :       bag?.discount || 0,
        color           :       bag?.color || ""
    }
}

export function validationSchema(){
    return Yup.object({
        trademark       :       Yup.string().required(true),
        line            :       Yup.string().required(true),
        dimentions      :       Yup.string().required(true)        
    })
}