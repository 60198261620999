export const data = [
    {
      image: "/images/bolsa.png",
      title: "Bolsas",
      description: "ABC de Bolsas",
      url: "/admin/bag"
    },
    {
      image: "/images/catalogo.png",
      title: "Catálogos",
      description: "ABC de Catálogos",
      url: "/admin/catalog"
    },
    {
      image: "/images/user.png",
      title: "Usuarios",
      description: "ABC de Usuarios",
      url: "/admin/user"
    }
  ];