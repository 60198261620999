import React from 'react';
import { Seo } from '../../../components/Shared';
import  { UserList } from '../../../components/Admin/User/UserList';
import { Tab } from 'semantic-ui-react';

import styles from "./User.module.scss";

export function UserPage() {
    return (        
            <div className={styles.User}>
            <Seo title="Admin Usuarios LUX & GLAM"/>
            <div className={styles.UserPage}>                
                <div className={styles.UserPageAdd}>
                    <div className={styles.titleLeft}><h1> Listado de Usuarios</h1></div>
                </div>
                <Tab.Pane attached={false}>
                    <UserList/>
                </Tab.Pane>
            </div>
        </div>        
    )
}
