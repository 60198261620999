import { ENV, authFetch } from "../utils";

export class Schema {
    async createSchema(data){
        try {
          const url = `${ENV.API_URL}/${ENV.ENDPOINTS.SCHEMA}`;
          const params = {
              method  :   "POST",
              headers :   {
                "Content-Type": "application/json",  
              },
              body: JSON.stringify(data),
            };
          
          const response  = await authFetch(url, params);            
          const result    = await response.json();
          
          if(response.status !== 200 ) throw result;
          
          return result;
    
        } catch (error) {
          throw error
        }
    }

    async getSchemas(page =1, limit = 10){
      try {
        const pageFilter    = `page=${page}`;
        const limitFilter   = `limit=${limit}`;
      
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.SCHEMA}?${pageFilter}&${limitFilter}`;
        const response = await fetch(url);
        const result = await response.json();
  
        if (response.status !== 200) throw result;
  
        return result.schema;
      } catch (error) {
        throw error;
      }
    }

    async updateSchema(id, data){
      try {

        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.SCHEMA}/${id}`;
        const params = {
            method  :   "PATCH",          
            headers :   {
              "Content-Type": "application/json"
            },
            body : JSON.stringify(data),
        };
        
        const response  = await authFetch(url, params);            
        const result    = await response.json();
  
        if(response.status !== 200 ) throw result;
        
        return result;  
  
      } catch (error) {
        throw error
      }
    }

    async deleteSchema(idBag){
      try {
        const url = `${ENV.API_URL}/${ENV.ENDPOINTS.SCHEMA}/${idBag}`;
        const params = {
            method  :   "DELETE",
        };

        const response  = await authFetch(url, params);            
        const result    = await response.json();

        if(response.status !== 200 ) throw result;

        return result;
      } catch (error) {
        throw error;
      }
    }
}