
import { useEffect, useState } from "react";
import { Bag } from "../../../api";
import { Container, Image, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Label } from "../../../components/Shared"; 
import { ENV } from "../../../utils";

import styles from "./BannerLastGamePublished.module.scss";

const bagCtrl = new Bag();

export function BannerLastGamePublished() {
    const [ bag, setBag ] = useState(null);

    useEffect(() =>{
        (
            async () =>{
                try {
                    const response = await bagCtrl.getLastPublished();
                    setBag(response.bag);
                } catch (error) {
                    console.error(error)
                }
            }
        )()
    }, [])

    if(!bag) return null;

    const wallpaper = `${ENV.SERVER_HOST}/${bag.wallpaper}`;

    return (
        <Grid>
            <Grid.Column only='mobile tablet' mobile={16} tablet={16}>
          
        <div className={styles.container}>
            
            <Image src= {wallpaper} className={styles.wallpaper}/>
            <Link className={styles.infoContainer} to={`/bag/${bag._id}`} >
                <Container>                    
                    <h2>{bag.trademark.value} {bag.line}</h2>
                    <p className={styles.price}>
                        {
                            bag.discount !== 0 &&
                                <Label.Discount > - {bag.discount}%</Label.Discount>                            
                        }
                                                
                    </p>
                </Container>
            </Link>
        </div>
        </Grid.Column>
            </Grid>

  )
}