import { Image, Grid } from "semantic-ui-react";
import { Account } from "../Account";
import { Menu } from "../Menu";
import { Link } from "react-router-dom";

import styles from "./TopBar.module.scss";

export function TopBar(props) {
    const { isOpenSearch } = props;

  return (
    <Grid className={styles.topBar}>
      <Grid.Column only='computer' computer={5}>
        <div className={styles.left}>
          <Link to="/">
            <Image src= "/images/logo.png" alt="Gaming" />
          </Link>
        </div>
      </Grid.Column>
      <Grid.Column mobile={14} tablet={8} computer={5}>
      <div className={styles.center}>
        <Menu isOpenSearch ={isOpenSearch} />
      </div>
      </Grid.Column>
      <Grid.Column mobile={2} tablet={8} computer={5}>
        <div className={styles.right}>
          <Account/>
        </div>
      </Grid.Column>
    </Grid>
  )
}