import { Seo } from '../../../components/Shared';

import { CatalogOptions  } from '../../../components/Admin/Catalog/CatalogOptions';

export function CatalogPage() {

    return (
        <>
            <Seo title="Catálogos LUX & GLAM"/>
            <CatalogOptions/>
        </>
    )
}
