export const data = [
    {
      image: "/images/catalogo2.png",
      title: "Esquemas",
      description: "ABC de Esquemas",
      url: "/admin/catalog/schema"
    },
    {
      image: "/images/Clasificacion.png",
      title: "Clasificaciones",
      description: "ABC de Clasificaciones",
      url: "/admin/catalog/classification"
    }
  ];