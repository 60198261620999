import React from 'react'
import { Routes, Route, Navigate} from "react-router-dom";
import { AdminLayout } from "../layouts";
import { Home } from "../pages/admin/Home";
import { Bag, CatalogPage } from "../pages/admin";
import { useAuth } from "../hooks";
import { SchemaPage, ClassificationPage, UserPage } from '../pages/admin';

export function AdminRouter() {
    const { user } = useAuth();

    const loadLayout =  (Layout, Page, isContainer, relative) => {
        return (
            <Layout isContainer={isContainer} relative={relative}> 
                <Page/>
            </Layout>
        )
    }

    return (
        <Routes>
            <Route path="/admin" element={user && user?.response.role === "ADMIN"  ? loadLayout(AdminLayout, Home) : <Navigate to="/" />} />
            <Route path='/admin/bag' element={user?.response.role === "ADMIN" ? loadLayout(AdminLayout, Bag) : <Navigate to="/" />} />
            <Route path='/admin/catalog' element={user?.response.role === "ADMIN" ? loadLayout(AdminLayout, CatalogPage) : <Navigate to="/" />} />
            <Route path='/admin/user' element={user?.response.role === "ADMIN" ? loadLayout(AdminLayout, UserPage) : <Navigate to="/" />} />
            <Route path='/admin/catalog/schema' element={user?.response.role === "ADMIN" ? loadLayout(AdminLayout, SchemaPage) : <Navigate to="/" />} />
            <Route path='/admin/catalog/classification' element={user?.response.role === "ADMIN" ? loadLayout(AdminLayout, ClassificationPage) : <Navigate to="/" />} />
        </Routes>
    )
}