import React from 'react';
import { Link } from "react-router-dom";
import { map } from "lodash";
import { Grid } from "semantic-ui-react";
import { data } from "./CatalogOptions.data";

import styles from "./CatalogOptions.module.scss";

export function CatalogOptions() {
    return(
        <>
            <h1 className={styles.titleAdmin}> Opciones de Catálogos</h1>
            <Grid>
                <Grid.Column only='computer' computer={16} >
                    <div className={styles.gridMenu}>
                        {
                            map(data, (item) =>(
                                <Link key={item.url} to={`${item.url}`} className={styles.menu}>
                                    <div>
                                        <img src={item.image} alt={item.title} />
                                    </div>
                                    <div className={styles.title}>
                                        <span>{item.title} </span>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </Grid.Column>
                <Grid.Column only='tablet mobile' tablet={16} mobile={16} >
                    <div className={styles.gridMenu}>
                        {
                            map(data, (item) =>(
                                <Link key={item.url} to={`${item.url}`} className={styles.menuMT}>
                                    <div>
                                        <img src={item.image} alt={item.title}/>
                                    </div>
                                    <div className={styles.title}>
                                        <span>{item.title} </span>
                                    </div>
                                </Link>
                            ))
                        }
                    </div>
                </Grid.Column>
            </Grid>
        </>
    )
}