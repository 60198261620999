import { ENV } from "../utils";
import jwtDecode from "jwt-decode";

export class Token {
  setToken(token) {
    localStorage.setItem(ENV.TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(ENV.TOKEN);
  }

  removeToken() {
    localStorage.removeItem(ENV.TOKEN);
  }

  hasExpired(token) {
    //const tokenDecode = jwtDecode(token);
    //const expireDate = tokenDecode.exp * 1000;
    //const currentDate = new Date().getTime();

    //if (currentDate > expireDate) {
    //  return true;
    //}

    const tokenDecode = jwtDecode(token);
    const currentDate = new Date().getTime();

    if(tokenDecode.exp <= currentDate){
      return true;
  }
    return false;
  }
}