import React, { useState } from 'react';
import { Tab, Button } from 'semantic-ui-react';
import { BasicModal } from '../../../components/Shared';
import { SchemaForm  } from './SchemaForm';
import { SchemaList  } from './SchemaList';

import styles from "./Schema.module.scss";

export function Schema() {
    const [ showModal, setShowModal ] = useState(false);
    const [ reload, setReload ] = useState(false);
    
    const onOpenCloseModal = () => setShowModal((prevState) => !prevState);
    const onReload = () => setReload((prevState) => !prevState);


    return (
        <div className={styles.Schema}>
            <div className={styles.SchemaPage}>                
                <div className={styles.SchemaPageAdd}>
                    <div className={styles.SchemaTitleLeft}><h1> Listado de Esquemas</h1></div>
                    <div className={styles.SchemaTitleRight}> <Button primary onClick={onOpenCloseModal}> Nuevo Esquema</Button> </div>
                    
                </div>
                <Tab.Pane attached={false}>
                    <SchemaList onReload={onReload} reload={reload}/>
                </Tab.Pane>
            </div>
            <BasicModal show={showModal} onClose={onOpenCloseModal} title="Crear Esquema">
                <SchemaForm onClose={onOpenCloseModal} onReload={onReload}/>
            </BasicModal>
        </div>
    )
}