import * as Yup from "yup";

export function initialValues(schema){
    return{
        id              :       schema?.id || "",
        name            :       schema?.name || "",
        description     :       schema?.description || ""
 
    }
}

export function validationSchema(){
    return Yup.object({
        id              :       Yup.string().required(true),
       
    })
}