import React, { useState } from 'react';
import { Tab } from 'semantic-ui-react';
import { SchemaList  } from './SchemaList';

import styles from "./Classification.module.scss";

export function Classification() {
    const [ reload, setReload ] = useState(false);
    
    const onReload = () => setReload((prevState) => !prevState);


    return (
        <div className={styles.Schema}>
            <div className={styles.SchemaPage}>                
                <div className={styles.SchemaPageAdd}>
                    <h1> Listado de Clasificaciones</h1>                    
                </div>
                <Tab.Pane attached={false}>
                    <SchemaList onReload={onReload} reload={reload}/>
                </Tab.Pane>
            </div>
        </div>
    )
}
