export const data = [
    {
      icon: "truck",
      title: "Envíos a toda Guatemala",
      description: "Entrega inmediata",
    },
    {
      icon: "tags",
      title: "Distintos métodos de pago",
      description: "Contra entrega, transferencia o depósito, tarjeta cuotas y contado ",
    },
    {
      icon: "wechat",
      title: "Atención al cliente",
      description: "Agente disponible 24/7",
    },
  ];