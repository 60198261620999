import React from 'react';
import { Home as HomeC } from "../../../components/Home";
import { Separator, BarTrust, BannerAd, Seo } from "../../../components/Shared";
import { Grid, Container } from "semantic-ui-react";
import { useAuth } from "../../../hooks";

export function Home() {
  const { user } = useAuth();

  return (
    <>
      <Seo />
      <Container>
        <Separator height={100} />
        <HomeC.BannerLastGamePublished/>
        <Separator height={50} />
        <Grid>
          <Grid.Column only='computer' computer={16}>
            <HomeC.LastestGames title="Ultimos Ingresos"/>
            <Separator height={50} />
          </Grid.Column>  
        </Grid>          
        <BarTrust/>
        
        {
          !user &&
          <>
            <Separator height={50} />       
            <BannerAd title="Registrate y sé el primero en enterarte de los nuevos ingresos" subtitle="Descuentos, remates y mucho más" btnTitle="Entrar ahora" btnLink="/join/sign-in" image="/images/logo.png" />      
          </>
        }
        <Separator height={50} />
      </Container>
    </>
  )
}
