import React, { useEffect, useState } from 'react';
import { Schema } from "../../../../api";
import { SchemaItem } from "../SchemaItem";
import { Loader, Pagination } from 'semantic-ui-react';
import { map, size } from 'lodash';

import styles from "./SchemaList.module.scss";

const SchemaController = new Schema();

export function SchemaList(props) {
    const { onReload, reload } = props;
    const [ schemas, setSchemas ] = useState(null);
    const [ pagination, setPagination ] = useState(null);
    const [ page, setPage ] = useState(1);

    useEffect(() => {
        (async () => {
            try {
                setSchemas(null);
                const response = await SchemaController.getSchemas(page);
                setSchemas(response.docs);
                setPagination({
                    limit       :       response.limit,
                    page        :       response.page,
                    pages       :       response.pages,
                    total       :       response.total
                })
            
            } catch (error) {
                console.error(error);
           }           
        })()

    },[page, reload]);

    const changePage = (_,data) => {
        setPage(data.activePage);
    }

    if(!schemas) return <Loader active inline="centered"/>
    if(size(schemas) === 0 ) return "No hay esquemas"; 


    return (
        <div className={styles.ListSchema}>
            {
                map(schemas, (schema, i) => (
                    <SchemaItem  onReload={onReload} key={i} schema={schema} />
                ))
            }
        
            <div className={styles.ListSchemaPagination}>
                <Pagination totalPages={pagination.pages} defaultActivePage={pagination.page} ellipsisItem={null} firstItem={null} lastItem={null} onPageChange={changePage}/>
            </div>
        </div>
    )
}